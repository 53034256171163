<template>
  <div class="">
    <!-- form -->
    <div class="bp_form_box">
      <div class="bp_form_side_bar">

        <div
          v-for="(data, index) in tab_items"
          :key="index"
          class="bp_tab_link"
          :class="is_tab_active === data.id ? 'bp_active' : ''"
          @click="showFormContent(data.id)"
        >
          <div class="bp_tab_counter">
            {{ index + 1 }}
          </div>
          <div class="bp_text_view">
            <span> {{ data.title }} </span>
          </div>
        </div>

      </div>
      <div class="bp_form_view">
        <div class="form_content">

          <validation-observer ref="registerForm">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="update ? updateUser() : createUser()"
            >
              <!-- username -->
              <div
                v-if="is_tab_active === 1"
                class="row"
              >
                <div class="col-md-6">
                  <b-form-group
                    label="First Name"
                    label-for="firstname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="firstname"
                      rules="required"
                    >
                      <b-form-input
                        id="firstname"
                        v-model="register.first_name"
                        :state="errors.length > 0 ? false:null"
                        name="register-firstname"
                        placeholder="john"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>

                <div class="col-md-6">
                  <b-form-group
                    label="Last Name"
                    label-for="lastname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="lastname"
                      rules="required"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="register.last_name"
                        :state="errors.length > 0 ? false:null"
                        name="register-lastname"
                        placeholder="doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
                <div class="col-md-12">
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="register.email"
                        :state="errors.length > 0 ? false:null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    label="Job Title"
                    label-for="job-title"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="job-title"
                      rules="required"
                    >
                      <b-form-input
                        id="job-title"
                        v-model="register.job_title"
                        :state="errors.length > 0 ? false:null"
                        name="register-job-title"
                        placeholder="job title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
                <div class="col-md-12">
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                    >
                      <b-form-input
                        id="phone"
                        v-model="register.phone"
                        :state="errors.length > 0 ? false:null"
                        name="phone"
                        placeholder="+000 0000 0000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-group
                        label="Address"
                        label-for="address1"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="address1"
                          rules="required"
                        >
                          <b-form-input
                            id="address1"
                            v-model="register.address1"
                            :state="errors.length > 0 ? false:null"
                            name="register-address1"
                            placeholder="address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>

                    <div class="col-4">
                      <b-form-group
                        label="City"
                        label-for="city"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="city"
                          rules="required"
                        >
                          <b-form-input
                            id="city"
                            v-model="register.city"
                            :state="errors.length > 0 ? false:null"
                            name="register-city"
                            placeholder="City"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>

                    <div class="col-4">
                      <b-form-group
                        label="State"
                        label-for="state"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="state"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            v-model="register.state"
                            :state="errors.length > 0 ? false:null"
                            name="register-state"
                            placeholder="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group
                        label="Zip Code"
                        label-for="zipcode"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="zipcode"
                          rules="required"
                        >
                          <b-form-input
                            id="zipcode"
                            v-model="register.zip_code"
                            :state="errors.length > 0 ? false:null"
                            name="register-zipcode"
                            placeholder="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-4">
                      <b-form-group
                        label="County"
                        label-for="county"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="county"
                          rules="required"
                        >
                          <b-form-input
                            id="county"
                            v-model="register.county"
                            :state="errors.length > 0 ? false:null"
                            name="register-county"
                            placeholder="County"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12 d-flex align-items-center justify-content-between">
                      <b-button
                        variant="outline-secondary"
                        type="button"
                        @click="hide"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        variant="outline-success"
                        type="submit"
                        :disabled="update && edited === false ? true : false"
                      >
                        Save
                      </b-button>
                    </div>

                  </div>
                </div>

              </div>

              <!-- <div
                v-if="is_tab_active === 2"
                class="row service_list_box"
              >
                <div
                  v-for="(review, index) in reviews"
                  :key="index"
                  class="col-md-12"
                >
                  <div class="review__card">
                    <div class="review_status_view mb-2">
                      <div class="review_profile_view">
                        <b-avatar
                          :src="getImage(review.business_profile.cover)"
                          size="100"
                        />
                        <div class="profile_details">
                          <h5>{{ review.business_profile && review.business_profile.name }}</span></h5>
                          <div class="raiting_stars">
                            <b-form-rating
                              v-model="review.rating"
                              variant="warning"
                              inline
                              no-border
                              readonly
                              class="raiting_box"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-badge
                      v-if="review.status === 'reported'"
                      :variant="review.status === 'active' ? 'light-success':
                        review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                      "
                    >
                      {{ review.status === 'reported' ? 'Review reported' : 'Review ' + review.status }}
                    </b-badge>
                    <p class="review_display_text mt-1">
                      {{ review.review | setTruncate(350, "...") }}
                    </p>
                    <div
                      class="extra_review_content"
                    >
                      <div class="row mb-1">
                        <div class="col-md-4 review-content">
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="mr-1"
                          />
                          <span class="review-content-heading">Customer Service</span>
                        </div>
                        <div class="col-md-8">
                          {{ review.content.customer_service }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-md-4 review-content">
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="mr-1"
                          />
                          <span class="review-content-heading">Pricing</span>
                        </div>
                        <div class="col-md-8">
                          {{ review.content.pricing }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-md-4 review-content">
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="mr-1"
                          />
                          <span class="review-content-heading">Timeliness</span>
                        </div>
                        <div class="col-md-8">
                          {{ review.content.timeliness }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-md-4 review-content">
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="mr-1"
                          />
                          <span class="review-content-heading">Quality</span>
                        </div>
                        <div class="col-md-8">
                          {{ review.content.quality }}
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-md-4 review-content">
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="mr-1"
                          />
                          <span class="review-content-heading">Recommendation</span>
                        </div>
                        <div class="col-md-8">
                          {{ review.content.recommend }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div
                v-if="is_tab_active === 2"
                class="d-flex align-items-center justify-content-between"
              >
                <h4>Can Login</h4>
                <b-form-checkbox
                  :checked="register && register.user_access.can_login === true ? 'true' : 'false'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  @change="toggleUser($event, register.user_access.id)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </b-form>
          </validation-observer>

        </div>
        <!-- <div
              v-if="is_tab_active === 2 && update"
              class="button_view"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalReviewRows"
                :per-page="reviewPerpage"
                size="lg"
                class="mt-3 pagination-success"
                align="center"
                @change="onReviewPageChange"
              />
            </div> -->
      </div>
    </div>

  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BButton, BFormInput, BFormGroup, BBadge, BAvatar, BFormRating, BFormCheckbox, BPagination,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BBadge,
    BFormRating,
    BFormCheckbox,
    BPagination,
  },
  mixins: [togglePasswordVisibility],
  props: {
    type: { type: String, default: '' },
    hide: { type: Function },
    disableEditted: { type: Function },
    update: { type: Boolean, default: false },
    edited: { type: Boolean, default: false },
  },
  data() {
    return {
      regEmail: '',
      username: '',
      status: '',
      // validation rules
      required,
      email,
      review_page: 1,
      reviewPerpage: 5,
      currentPage: 1,
      tab_items: [
        { id: 1, title: 'Infomation' },
      ],
      is_tab_active: 1,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    register: {
      get() {
        return this.$store.state.auth.userRegister
      },
      set(value) {
        return value
      },
    },
    reviews() {
      return this.$store.state.review.reviews
    },
    totalReviewRows() {
      return this.$store.state.review.total_reviews
    },
  },
  watch: {
    edited: {
      handler(newValue, oldValue) {
        if (oldValue === true || newValue === true) {
          const tabs = this.tab_items.filter(tab => tab.id === 2)
          if (tabs.length < 1) {
            this.tab_items.push({ id: 2, title: 'Status' })
          }
        } else {
          const tabs = this.tab_items.filter(tab => tab.id !== 2)
          this.tab_items = tabs
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.register.user_type = this.type
  },
  methods: {
    createUser() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/registerStaff', this.register)
          this.hide()
          this.emptyData()
        }
      })
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.$store.dispatch('auth/emptyData')
      }, 300)
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    // onReviewPageChange(page) {
    //   this.review_page = page
    //   return this.$store.dispatch('review/getBusinessReviews', { page, per_page: this.reviewPerpage, member_id: this.register.member.id })
    // },
    updateUser() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/updateRegisterStaffProfile', this.register)
          this.disableEditted()
          const dom = this
          setTimeout(() => {
            this.hide()
            dom.$store.dispatch('user/getStaffs', { page: 1, per_page: 10, user_type: dom.type === 'manager' ? 'staff' : dom.type })
          }, 100)
        }
      })
    },
    toggleUser(status, id) {
      const data = { user_id: id, can_login: status }
      return this.$store.dispatch('user/toggleUser', data)
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
